<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <p-divider class="w-full" [style]="{ 'margin-top': '-1rem', 'padding-bottom': '1rem' }" align="left">
    <b>{{ moduleString + '.generalInformation' | translate }}</b>
  </p-divider>
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.firstName.label' | translate: 'First Name'"
        [placeholder]="moduleFieldString + '.firstName.placeholder' | translate: 'Enter First Name'"
        [control]="formGroup?.controls?.firstName"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.userName.label' | translate: 'User Name'"
        [placeholder]="moduleFieldString + '.userName.placeholder' | translate: 'Enter User Name'"
        [control]="formGroup?.controls?.userName"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.lastName.label' | translate: 'Last Name'"
        [placeholder]="moduleFieldString + '.lastName.placeholder' | translate: 'Enter Last Name'"
        [control]="formGroup?.controls?.lastName"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <!-- <div class="col-12 md:col-6">
    <app-role-selector [label]="'User Roles'"
      [placeholder]="'Search Roles'"
      [control]="formGroup?.controls?.roles"
      [multi]="true"
      [optionValue]="undefined"
      [viewMode]="fieldViewMode">
    </app-role-selector>
  </div> -->
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.email.label' | translate: 'Email'"
        [placeholder]="moduleFieldString + '.email.placeholder' | translate: 'Enter Email'"
        [control]="formGroup?.controls?.email"
        [viewMode]="fieldViewMode"
        [type]="'email'"
      ></app-basic-input>
    </div>
    <!-- <div class="col-12 md:col-6">
  <app-check-box [label]="'Enabled'"
    [name]="'enabled-user'"
    [control]="formGroup?.controls?.enable"
    [viewMode]="fieldViewMode">
  </app-check-box>
</div> -->
  </div>
  <p-divider class="w-full" align="left">
    <b>{{ moduleString + '.contactInformation' | translate }}</b>
  </p-divider>
  <div class="formgrid grid p-fluid">
    @if (contactInfoFields.length > 0) {
      <div class="col-12">
        <app-form-repeater
          [viewMode]="fieldViewMode"
          [title]="''"
          [label]="moduleString + '.contactInformation' | translate"
          [cols]="contactInfoFields"
          [control]="contactInfos"
          [selectionMode]="null"
          [control]="formGroup?.controls.attributes"
          [showAddFormAsPopup]="true"
        ></app-form-repeater>
      </div>
    }
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
