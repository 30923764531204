import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAttributesDataService extends BaseRequestControllerService<any> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.user, '/userAttributes', []);
  }

  updateAttributesByUserId(userName: string, body: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<any>(`${this.url}/${userName}`, body, options) as Observable<BaseResponse<any>>;
  }

  getAttributesByUserId(userName: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<any>(`${this.url}/${userName}`, options) as Observable<any>;
  }

  navigateToListPage() {
    this.router.navigateByUrl(`user-management/user/list`);
  }
}
