import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, DataTypeEnum, IDynamicComponent, User, getEnumOptions } from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { ViewModeService } from '@shared/services/view-mode.service';
import { uniqueId } from 'lodash-es';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent extends BaseForm<User> implements OnInit {
  address: FormControl = new FormControl();
  company: FormControl = new FormControl();
  title: FormControl = new FormControl();
  phone: FormControl = new FormControl();
  contactInfos: FormControl = new FormControl({});
  contactInfoFields: IDynamicComponent[] = [];
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'USER');
  }

  ngOnInit(): void {
    this.initFormRepeaterFields();
  }

  getData() {
    let resData = {};

    let attributes = this.formGroup.getRawValue().attributes;

    attributes.forEach((element) => {
      resData['contactInfo' + uniqueId()] = JSON.stringify(element).substring(0, 255);
    });

    return { ...this.formGroup.value, attributes: resData };
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
    if (data) {
      this.formGroup.controls.userName.disable();
    }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      userName: new FormControl({ value: null, disabled: true }, Validators.required),
      email: new FormControl({ value: null, disabled: true }, Validators.email),
      firstName: new FormControl({ value: null, disabled: true }),
      lastName: new FormControl({ value: null, disabled: true }),
      roles: new FormControl({ value: null, disabled: true }),
      enable: new FormControl({ value: false, disabled: true }),
      attributes: new FormControl(null),
    });
  }
  initFormRepeaterFields() {
    this.contactInfoFields = [
      {
        componentType: DropDownInputComponent,
        options: {
          label: 'Attribute Type',
          name: 'type',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            dropDownInput: {
              multi: false,
              showClear: true,
              optionLabel: 'label',
              optionValue: 'value',
              items: getEnumOptions(ProfileAttributes.ProfileAttributesEnum),
              badgeView: true,
              appendTo: 'body',
            },
          },
          dataType: DataTypeEnum.Badge,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Title',
          name: 'title',
          control: new FormControl('', [Validators.required, Validators.maxLength(60)]),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Value',
          name: 'value',
          control: new FormControl('', [Validators.required, Validators.maxLength(170)]),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    ];
  }
}

export namespace ProfileAttributes {
  export type ProfileAttributesEnum = 'COMPANY' | 'ADDRESS' | 'PHONE' | 'EMAIL';
  export const ProfileAttributesEnum = {
    Company: 'COMPANY' as ProfileAttributesEnum,
    Address: 'ADDRESS' as ProfileAttributesEnum,
    Phone: 'PHONE' as ProfileAttributesEnum,
    Email: 'EMAIL' as ProfileAttributesEnum,
  };
}
