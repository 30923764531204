<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()" autocomplete="off">
  <div class="formgrid grid p-fluid">
    <div style="height: 0; width: 0; overflow: hidden">
      <div>
        <label>username</label>
        <input style="opacity: 0" type="text" name="username" value="" class="" />
      </div>
      <input style="opacity: 0" type="password" value="" class="" />
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.userName.label' | translate: 'User Name'"
        [placeholder]="moduleFieldString + '.userName.placeholder' | translate: 'Enter User Name'"
        [control]="formGroup?.controls?.userName"
        [viewMode]="fieldViewMode"
        [canKeepValue]="false"
        [name]="'new-user-field'"
      ></app-basic-input>
    </div>
    @if (fieldViewMode == 'create') {
      <div class="col-12 md:col-6">
        <app-basic-input
          [label]="moduleFieldString + '.password.label' | translate: 'Password'"
          [placeholder]="moduleFieldString + '.password.placeholder' | translate: 'Enter Password'"
          [control]="formGroup?.controls?.password"
          [viewMode]="fieldViewMode"
          [type]="'password'"
          [canKeepValue]="false"
          [name]="'new-pass-field'"
        ></app-basic-input>
      </div>
    }
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.firstName.label' | translate: 'First Name'"
        [placeholder]="moduleFieldString + '.firstName.placeholder' | translate: 'Enter First Name'"
        [control]="formGroup?.controls?.firstName"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.lastName.label' | translate: 'Last Name'"
        [placeholder]="moduleFieldString + '.lastName.placeholder' | translate: 'Enter Last Name'"
        [control]="formGroup?.controls?.lastName"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.email.label' | translate: 'Email'"
        [placeholder]="moduleFieldString + '.email.placeholder' | translate: 'Enter Email'"
        [control]="formGroup?.controls?.email"
        [viewMode]="fieldViewMode"
        [type]="'email'"
        [canKeepValue]="false"
      ></app-basic-input>
    </div>
    <!-- <div class="col-12 md:col-6">
    <app-role-selector
      [label]="'User Roles'"
      [placeholder]="'Search Roles'"
      [control]="formGroup?.controls?.roles"
      [multi]="true"
      [optionValue]="undefined"
      [viewMode]="fieldViewMode"
      >
    </app-role-selector>
  </div> -->
    <div class="col-12 md:col-6">
      <app-check-box
        [label]="moduleFieldString + '.email.label' | translate: 'Enabled'"
        [name]="'enabled-user'"
        [control]="formGroup?.controls?.enable"
        [viewMode]="fieldViewMode"
      >
      </app-check-box>
    </div>
    <div class="col-12 md:col-6">
      <app-check-box
        [label]="'Email Verified'"
        [name]="'email-verified-user'"
        [control]="formGroup?.controls?.emailVerified"
        [viewMode]="fieldViewMode"
      >
      </app-check-box>
    </div>
    <div class="col-12 md:col-6">
      <app-check-box
        [label]="'System Admin'"
        [name]="'system-admin'"
        [control]="formGroup?.controls?.sfSysAdmin"
        [viewMode]="fieldViewMode"
      >
      </app-check-box>
    </div>
    <p-divider class="w-full" align="left">
      <b>{{ contactUserRolesLabel }}</b>
    </p-divider>
    <div class="col-12">
      <app-role-picklist-selector
        (resultlements)="setRoles($event)"
        [targetElements]="selectedRoles"
        [viewMode]="fieldViewMode"
      >
      </app-role-picklist-selector>
    </div>
    <p-divider class="w-full" align="left">
      <b>{{ contactInfoLabel }}</b>
    </p-divider>

    @if (contactInfoFields.length > 0) {
      <div class="col-12">
        <app-form-repeater
          [showAddFormAsPopup]="true"
          [viewMode]="fieldViewMode"
          [title]="''"
          [label]="moduleFieldString + '.contactInfo.label' | translate: 'Contact Details'"
          [cols]="contactInfoFields"
          [control]="formGroup?.controls?.contactInfo"
          [showAddFormAsPopup]="true"
          [selectionMode]="null"
        ></app-form-repeater>
      </div>
    }
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
