import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, first, map, Observable, of, switchMap } from 'rxjs';
import { UserDataService } from '../../services/data/user-data.service';

export function emailTakenValidator(userDataService: UserDataService, currentData: any): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return control.valueChanges.pipe(
      debounceTime(300), // Wait for 200ms after the user stops typing
      distinctUntilChanged(), // Only trigger if the email value has changed
      switchMap((value: string) => {
        // Skip validation if email hasn't changed (compare to current data)
        if (currentData && currentData.email === value) {
          return of(false); // Email hasn't changed, so it's valid
        }

        // Otherwise, make the API call to check if the email is taken
        return userDataService.checkEmailTaken(value).pipe(
          map((isTaken: boolean) => {
            return isTaken ? true : false;
          }), // Return `taken: true` if email is already taken
          catchError(() => of(false)) // If API fails, treat as valid (no error)
        );
      }),
      map((taken: boolean) => (taken ? { taken: true } : null)),
      first() // important to make observable finite
    );
  };
}
export function usernameTakenValidator(userDataService: UserDataService, currentData: any): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return control.valueChanges.pipe(
      debounceTime(300), // Wait for 200ms after the user stops typing
      distinctUntilChanged(), // Only trigger if the username value has changed
      switchMap((value: string) => {
        // Skip validation if username hasn't changed (compare to current data)
        if (currentData && currentData.userName === value) {
          return of(false); // username hasn't changed, so it's valid
        }

        // Otherwise, make the API call to check if the username is taken
        return userDataService.checkUsernameTaken(value).pipe(
          map((isTaken: boolean) => {
            return isTaken ? true : false;
          }), // Return `taken: true` if username is already taken
          catchError(() => of(false)) // If API fails, treat as valid (no error)
        );
      }),
      map((taken: boolean) => (taken ? { taken: true } : null)),
      first() // important to make observable finite
    );
  };
}
