import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  BaseForm,
  DataTypeEnum,
  IDynamicComponent,
  ModuleKeywordRootPath,
  ModuleKeywords,
  getEnumOptions,
  routeToLocaleCase,
  toCamelCase,
} from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { ViewModeService } from '@shared/services/view-mode.service';
import { uniqueId } from 'lodash-es';
import { UserAttributesDataService } from '../../services/data/user-attributes.service';
import { UserDataService } from '../../services/data/user-data.service';
import { ProfileAttributes } from '../profile/profile-form/profile-form.component';
import { emailTakenValidator, usernameTakenValidator } from './taken.validator';
@Component({
  selector: 'app-user-item-form',
  templateUrl: './user-item-form.component.html',
  styleUrls: ['./user-item-form.component.scss'],
})
export class UserItemFormComponent extends BaseForm<any> implements OnInit {
  contactInfoFields: IDynamicComponent[] = [];
  contactInfos: FormControl = new FormControl({});
  sysAdmin: FormControl = new FormControl(false);
  readonly sysAdminKey: 'SF_SYS_ADMIN' = 'SF_SYS_ADMIN';
  selectedRoles = [];
  currentRoles: any[];
  constructor(
    public viewModeService: ViewModeService,
    public userAttributesDataService: UserAttributesDataService,
    public userDataService: UserDataService,
    private localeService: LocaleService
  ) {
    super(viewModeService, ModuleKeywords.User);
  }

  ngOnInit(): void {
    this.initFormRepeaterFields();
    // this.formGroup.controls.email.addAsyncValidators([emailTakenValidator(this.userDataService, this.data)]);
    // this.formGroup.controls.userName.addAsyncValidators([usernameTakenValidator(this.userDataService, this.data)]);
  }

  translateFormFields(key, fallback) {
    return this.localeService.translate(
      `modules.${routeToLocaleCase(ModuleKeywordRootPath[ModuleKeywords.User])}.${toCamelCase(ModuleKeywords.User)}.fields.` +
      key +
      '.label',
      fallback
    );
  }

  initFormRepeaterFields() {
    this.contactInfoFields = [
      {
        componentType: DropDownInputComponent,
        options: {
          label: this.translateFormFields('type', 'Attribute Type'),
          name: 'type',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            dropDownInput: {
              multi: false,
              showClear: true,
              optionLabel: 'label',
              optionValue: 'value',
              items: getEnumOptions(ProfileAttributes.ProfileAttributesEnum),
              badgeView: true,
              appendTo: 'body',
            },
          },
          dataType: DataTypeEnum.Badge,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: this.translateFormFields('title', 'Title'),
          name: 'title',
          control: new FormControl('', [Validators.required, Validators.maxLength(60)]),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: this.translateFormFields('value', 'Value'),
          name: 'value',
          control: new FormControl('', [Validators.required, Validators.maxLength(170)]),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    ];
  }

  getData() {
    const contactInfo = {};
    this.formGroup?.getRawValue()?.contactInfo?.forEach((element, index) => {
      contactInfo['contactInfo' + (index + 1)] = JSON.stringify(element);
    });

    return this.viewModeService.viewMode == 'create'
      ? {
        ...this.formGroup.getRawValue(),
        contactInfo: contactInfo,
      }
      : {
        //   userName: this.formGroup.getRawValue().userName,
        //   email: this.formGroup.getRawValue().email,
        //   firstName: this.formGroup.getRawValue().firstName,
        //   lastName: this.formGroup.getRawValue().lastName,
        //   roles: this.formGroup.getRawValue().roles,
        //   enable: this.formGroup.getRawValue().enable,
        ...this.formGroup.getRawValue(),
        contactInfo: contactInfo,
      };
    // return this.viewModeService.viewMode == 'create' ? this.getDataKeyValueFormat() : this.getChangedFormValues().updateItems
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
    this.selectedRoles = data?.roles;
    if (data) {
      this.formGroup.controls.userName.disable();
      this.formGroup.controls.password.disable();
    }
    this.contactInfos.setValue(this.getAttributesFromResponse(data?.contactInfo));
    this.formGroup?.controls?.contactInfo?.setValue(this.getAttributesFromResponse(data?.contactInfo));
    // this.formGroup?.controls?.sfSysAdmin?.setValue(data?.contactInfo?.[this.sysAdminKey]?.[0] === 'true');
    if (data?.attributes?.[this.sysAdminKey]?.[0] === 'true') {
      this.sysAdmin.patchValue(true);
    } else {
      this.sysAdmin.patchValue(false);
    }
    // if(data?.userName) {
    //     this.userAttributesDataService
    //         .getAttributesByUserId(data?.userName)
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe(attrs => {
    //             this.contactInfos.setValue(this.getAttributesFromResponse(attrs));
    //             if(attrs?.[this.sysAdminKey]?.[0] === 'true'){
    //                 this.sysAdmin.patchValue(true);
    //             }else{
    //                 this.sysAdmin.patchValue(false);
    //             }
    //         })
    // }
    this.formGroup.controls.email.clearAsyncValidators();
    this.formGroup.controls.userName.clearAsyncValidators();

    this.formGroup.controls.email.updateValueAndValidity();
    this.formGroup.controls.userName.updateValueAndValidity();

    this.formGroup.controls.email.addAsyncValidators(this.checkIfEmailIsTaken());
    this.formGroup.controls.userName.addAsyncValidators(this.checkIfUsernameIsTaken());
  }

  getAttrData() {
    let resData = {};

    let attributes = this.contactInfos.value;

    attributes?.forEach((element) => {
      resData['contactInfo' + uniqueId()] = [JSON.stringify(element)?.substring(0, 255)];
    });
    resData[this.sysAdminKey] = this.sysAdmin.getRawValue() === true ? ['true'] : ['false'];
    return resData;
  }

  getAttributesFromResponse(innerResponse: any) {
    if (!innerResponse) return [];

    let resArr = [];

    const entriesArray: any = Object.entries(innerResponse);

    entriesArray.forEach((el) => {
      el[0].startsWith('contactInfo') ? resArr.push(JSON.parse(el[1])) : null;
    });

    return resArr;
  }

  // onSubmitForm() {
  //     this.updateItem(this.getAttrData())
  // }
  // onSubmitFormSave(){
  //     this.updateItem(this.getAttrData())
  // }

  // updateItem(data,redirectToPage = true) {

  //     let newData = {
  //         data: this.getData(),
  //         attributesData: data
  //     };
  //     redirectToPage ? this.formSubmit.emit(newData) : this.formSubmitSave.emit(newData);
  // }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      userName: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      email: new FormControl(null, [
        Validators.required, // Add any other validators you need here
        Validators.email,
      ]),
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      roles: new FormControl(null),
      enable: new FormControl(false),
      emailVerified: new FormControl(false),
      sfSysAdmin: new FormControl(false),
      contactInfo: new FormControl(null),
    });
    // this.formGroup.controls.userName.valueChanges.pipe(debounceTime(200)).subscribe((value) => {
    //   if ((this.data && this.data.userName != value) || !this.data) {
    //     this.userDataService.checkUsernameTaken(value).subscribe((res) => {
    //       if (res) {
    //         this.formGroup.controls.userName.setErrors({ taken: true });
    //       } else {
    //         this.formGroup.controls.userName.setErrors(null);
    //       }
    //     });
    //   }
    // });
    // this.formGroup.controls.email.valueChanges.pipe(debounceTime(200)).subscribe((value) => {
    //   if ((this.data && this.data.email != value) || !this.data) {
    //     this.userDataService.checkEmailTaken(value).subscribe((res) => {
    //       if (res) {
    //         this.formGroup.controls.email.setErrors({ taken: true });
    //       } else {
    //         this.formGroup.controls.email.setErrors(null);
    //       }
    //     });
    //     this.formGroup.controls.email.updateValueAndValidity();
    //   }
    // });
  }
  setRoles(event) {
    this.formGroup.patchValue({ roles: event?.map((role) => role?.name) || [] });
    this.currentRoles = [...event];
  }
  checkIfEmailIsTaken() {
    return emailTakenValidator(this.userDataService, this.data);
  }
  checkIfUsernameIsTaken() {
    return usernameTakenValidator(this.userDataService, this.data);
  }

  get contactInfoLabel() {
    return this.localeService.translate(
      `modules.${routeToLocaleCase(ModuleKeywordRootPath[ModuleKeywords.User])}.${toCamelCase(ModuleKeywords.User)}.contactInformation.`, 'Contact Information'
    );
  }

  get contactUserRolesLabel() {
    return this.localeService.translate(
      `modules.${routeToLocaleCase(ModuleKeywordRootPath[ModuleKeywords.User])}.${toCamelCase(ModuleKeywords.User)}.userRoles.`, 'User Roles'
    );
  }
}
